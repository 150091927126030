$kendo-color-primary: #000;
$button-border-radius: 5px !default;
$kendo-grid-border: white;
$kendo-table-hover-bg: k-color-darken(#ffff, 0%);
$kendo-table-bg: #ffff;
$kendo-toolbar-bg: #ffff;
$kendo-grid-footer-bg: #ffff;
$kendo-pager-bg: #ffff;
$kendo-pager-focus-shadow: null;
$kendo-pager-item-selected-bg: #000;
$kendo-pager-border-width: 0px;
$kendo-pager-focus-border-color: transparent;
$kendo-grid-header-gradient: white;
$kendo-table-border-width: 0px;
$kendo-table-header-border: white;
@import "@progress/kendo-theme-default/scss/all.scss";

html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
body {
  margin: 0;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
}

.k-animation-container.k-chart-tooltip-wrapper.k-animation-container-shown
  > .k-popup {
  border-radius: 5px;
  display: block;
  table:hover {
    display: none;
  }
  offset: 0, -50;
}


